<!-- Home Body -->

<body>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TFK4FF"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->


    <div class="content p-0 main-top-row" role="main">

        <!-- Welcome Message -->
        <!-- <div class="row main-top-row">
      <h1 class="p-3 welcome-title">Welcome to</h1>
    </div> -->
        <div class="container logo-cont text-center">
            <img class="wsb-logo" src="https://cdn.worldsportsbetting.co.za/images/club-prive/WSB---ClubPrive-white-logo.png" alt="wsb home logo" title="home">
            <!-- <img class="wsb-logo-mobile" src="https://cdn.worldsportsbetting.co.za/images/club-prive/WSB-ClubPrive-blk.png" alt="wsb home logo" title="home"> -->
        </div>
        <!-- <div class="container product-title-cont">
      <h1><span class="header-subtext pr-2 product-title">Club</span> <span class="pr-4 product-title-2"
          style="font-family: Lato; font-weight: normal; font-size: 80px;">PRIVE</span>
      </h1>
    </div> -->

        <div class="ml-5">
            <div class="row nav-btns">
                <button type="button" class="btn btn-gold btn-lg mr-2 mb-1" routerLink="/myaccount">My Account</button>
                <button type="button" class="btn btn-gold btn-lg mr-2 mb-1" routerLink="/about">About</button>
                <button type="button" class="btn btn-gold btn-lg mr-2 mb-1" routerLink="/tiers">Tiers</button>
                <button type="button" class="btn btn-gold btn-lg mr-2 mb-1" routerLink="/rewards">Rewards</button>
                <button type="button" class="btn btn-gold btn-lg mr-2 mb-1" routerLink="/tscs">Ts & Cs</button>
            </div>
        </div>
        <div class="col-sm-12 row d-flex justify-content-center">
            <a type="button" class="btn btn-gold-sm btn-lg mt-4 play-now-btn" href="https://wc.worldsportsbetting.co.za/"></a>

        </div>

    </div>
</body>