import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  open: boolean = true; 
  loggedIn: Observable<boolean>;

  

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.loggedIn = this.authService.isLoggedIn;
  };

  hamburgerToggleHandler() {
    this.open = !this.open;
  }

  onLoginHandler() {

  }

  onLogoutHandler() {
    this.authService.logout();
    const returnUrl = this.router.navigate(['/login']);
    // this.loggedIn = !this.loggedIn;
  }

}
