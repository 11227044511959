<div class="container-fluid">
  <div class="row">
<!-- Footer -->
<footer class="footer-cont">
</footer>

<!--Payment Options-->
<div class="payment-methods">
  <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/card-visa.png" class="p-2 mr-2"
    style="width: 5vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
    <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/card-mastercard.png" class="p-2 mr-2"
    style="width: 5vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
    <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/eft.png" class="p-2 mr-2"
    style="width: 5vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
    <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/ottvoucher.png" class="p-2 mr-2"
    style="width: 11vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
    <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/OZOW.png" class="p-2 mr-2"
    style="width: 9vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="80" height="50" viewBox="0 0 1219.29 384.12" style="enable-background:new 0 0 1219.29 384.12;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#FF5F00;}
  </style>
  <g>
    <polygon class="st0" points="401.56,109.34 424.33,109.34 470.56,242.33 516.56,109.34 539.56,109.34 482.4,268.75 458.49,268.75 	
      "/>
    <path class="st0" d="M527.93,211.82c0-33.7,26.64-59.89,59.89-59.89c33.24,0,60.12,26.19,60.12,59.89s-26.87,59.89-60.12,59.89
      C554.57,271.71,527.93,245.52,527.93,211.82 M628.13,211.82c0-23-17.54-40.53-40.31-40.53c-22.54,0-40.08,17.53-40.08,40.53
      s17.54,40.53,40.08,40.53C610.59,252.35,628.13,234.82,628.13,211.82"/>
    <path class="st0" d="M760.42,154.89v113.86h-19.81v-16.4c-8.43,13.44-20.95,19.36-36.89,19.36c-26.64,0-44.18-17.76-44.18-46.91
      v-69.91h19.81v68.77c0,18.67,10.7,29.15,28.24,29.15c18.22,0,33.02-10.7,33.02-37.57v-60.34H760.42z"/>
    <path class="st0" d="M773.84,211.82c0-33.7,25.5-59.89,59.89-59.89c22.54,0,41.67,11.84,50.33,29.83l-16.62,9.56
      c-5.69-12.07-18.44-20.04-33.7-20.04c-22.77,0-40.08,17.53-40.08,40.53c0,22.77,17.31,40.31,40.08,40.31
      c15.48,0,28.01-7.97,34.39-19.81l16.85,9.79c-9.56,17.76-28.92,29.6-51.24,29.6C799.35,271.71,773.84,245.52,773.84,211.82"/>
    <path class="st0" d="M995.63,198.84v69.91h-19.81v-68.77c0-18.67-10.7-29.15-28.24-29.15c-18.22,0-33.02,10.7-33.02,37.57v60.34
      h-19.81v-159.4h19.81v61.94c8.43-13.44,20.95-19.36,36.89-19.36C978.1,151.93,995.63,169.69,995.63,198.84"/>
    <path class="st0" d="M1069.17,253.03c15.71,0,27.1-7.29,32.79-16.63l16.85,9.57c-10.02,15.48-27.33,25.73-50.1,25.73
      c-36.66,0-61.03-25.73-61.03-59.89c0-33.7,24.14-59.89,59.66-59.89c34.39,0,56.7,28.24,56.7,60.12c0,2.96-0.23,5.92-0.68,8.88
      h-95.41C1031.83,241.42,1047.99,253.03,1069.17,253.03 M1027.96,203.62h76.06c-3.42-22.54-19.58-33.02-36.66-33.02
      C1045.95,170.6,1031.15,183.81,1027.96,203.62"/>
    <path class="st0" d="M1192.59,152.84v20.72c-17.08-0.69-35.52,7.97-35.52,34.84v60.34h-19.81V154.89h19.81v19.13
      C1164.35,158.08,1178.24,152.84,1192.59,152.84"/>
    <path class="st0" d="M297.55,36H77.5c-24.37,0-44.12,19.75-44.12,44.12v220.05c0,24.37,19.75,44.12,44.12,44.12h220.05
      c24.36,0,44.12-19.75,44.12-44.12V80.12C341.66,55.75,321.91,36,297.55,36 M160.7,157.45c0,1.58-0.86,2.97-2.13,3.9
      c-1.31,0.96-3.69,2.56-5.05,3.33c-1.57,0.9-3.49,0.99-5.01,0.01L108.1,145c-2.84-1.81-2.86-5.95-0.04-7.8l45.46-34.08
      c3.09-2.02,7.19,0.19,7.19,3.88V157.45z M234.62,297.42c0,5.48-6.1,8.74-10.65,5.71l-48.38-32.18c-1.91-1.27-3.06-3.46-3.06-5.76
      c0-43.34,0-173.3,0-173.3c0-5.47,6.08-8.74,10.64-5.72l43.71,28.95c4.83,3.2,7.74,8.61,7.74,14.4V297.42z"/>
  </g>
  </svg>
    <!-- <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/1voucher.png" class="p-2 mr-2"
    style="width: 8vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;"> -->
    <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/instant-eft-payfast.png" class="p-2 mr-2"
    style="width: 8vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
    <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/blu-voucher-2.png" class="p-2 mr-2"
    style="width: 8vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
    <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/skrill-3.png" class="p-2 mr-2"
    style="width: 7vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
    <img
    src="https://cdn.worldsportsbetting.co.za/images/mobilev2/payment-methods/Netellar.png" class="p-2 mr-2"
    style="width: 7vh;margin-top: auto;margin-bottom: auto;padding: 0px !important;">
  </div>
<!--WSB Address-->
<div class="address-container p-3 col-12">
  <div class="row" style="max-width: 960px;">
    <div class="text-center">
      <div class="container">
        <div class="row terms-logos">
        <img class="terms-icon-1" src="https://cdn.worldsportsbetting.co.za/images/mobilev2/responsible-gaming/terms-icon.png" class="p-1"
          style="
              width: 45px;
              height: 45px;
          ">
          <img class="terms-icon-2" src="https://cdn.worldsportsbetting.co.za/images/mobilev2/responsible-gaming/18Plus.png" class="p-1"
          style="
              width: 45px;
              height: 45px;
          ">
          <img class="terms-icon-3" src="https://cdn.worldsportsbetting.co.za/images/mobilev2/responsible-gaming/ngb.png" class="p-1"
          style="
              width: 75px;
              height: 50px;
          ">
          <img class="terms-icon-4" src="https://cdn.worldsportsbetting.co.za/images/mobilev2/responsible-gaming/ggb.png" class="p-1"
          style="
              width: 55px;
              height: 45px;
          ">
          </div>
        </div>
          <p>
      Terms & Conditions
      </p>
    </div>
    <div class="container text-center col-5 terms">
    <p>
      World Sports Betting PTY Ltd is Licensed by the Gauteng
      Gambling Board with license number RAN004.
      Registered Address: Shop D1260 Banbury Cross Village Cnr.
      Malibongwe and Olievenhout Ave. North Riding, 2164
      </p>
    </div>
  </div>
</div>

<!--Cheesy slogan-->
<div class="sponsors-container p-3">
  <div class="container text-center">
      <p>
    Bet with your head, not with your heart... National Responsible Gambling Program - 0800 006 008
    </p>
  </div>
</div>
<!--Sponsors-->
<div class="sponsors-container p-3">
  <div class="container text-center">
    <p>
    Proud Sponsors:
    </p>
    <img class="p-2" style="width: 74px;" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/bulls-logo.png" alt="Proud Partner of the Bulls Rugby team">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/mobilev2/sponsorships/EFC-Logo.png" alt="Proud Partner of EFC">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/Gold-Circle-Icon.png" alt="Gold Circle">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/4Racing-Icon.png" alt="4 Racing" style="width: 40px;">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/Smiths-Boxing-Icon.png" alt="Proud Partner of Smiths Boxing">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/iDiski-Times-Logo-White.png" alt="Proud Partner of iDiski Times" style="width: 120px;">
    <!-- <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/1900-Icon.png" alt="Proud Partner of 1900">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/Champions-Cup-Icon.png" alt="Proud Partner of Champions Cup">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/Gold-Cup-Icon.png" alt="Proud Partner of Gold Cup">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/Fillies-Guineas-Icon.png" alt="Proud Partner of Fillies Guineas">
    <img class="p-2" src="https://cdn.worldsportsbetting.co.za/images/desktop/footer/Poly-Challenge-Icon.png" alt="Proud Partner of Poly Challenge"> -->
  </div>
</div>

<!-- Links -->
<div class="card-container">
  <a class="square-link-fb" title="Facebook" href="https://www.facebook.com/WorldSportsBet" target="_blank"
    rel="noopener">
    <svg _ngcontent-wwo-c16="" id="fb-logo" width="20" height="20" xmlns="http://www.w3.org/1999/xlink" data-name="Logo"
      viewBox="0 0 25 20">
      <path _ngcontent-wwo-c16=""
        d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
        fill="#fff">
      </path>
    </svg>
  </a>

  <a class="square-link-twitter" title="Twitter" href="https://twitter.com/WorldSportsBet" target="_blank"
    rel="noopener">
    <svg id="twitter-logo" height="24" data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
      <rect width="400" height="400" fill="none" />
      <path
        d="M153.62,301.59c94.34,0,145.94-78.16,145.94-145.94,0-2.22,0-4.43-.15-6.63A104.36,104.36,0,0,0,325,122.47a102.38,102.38,0,0,1-29.46,8.07,51.47,51.47,0,0,0,22.55-28.37,102.79,102.79,0,0,1-32.57,12.45,51.34,51.34,0,0,0-87.41,46.78A145.62,145.62,0,0,1,92.4,107.81a51.33,51.33,0,0,0,15.88,68.47A50.91,50.91,0,0,1,85,169.86c0,.21,0,.43,0,.65a51.31,51.31,0,0,0,41.15,50.28,51.21,51.21,0,0,1-23.16.88,51.35,51.35,0,0,0,47.92,35.62,102.92,102.92,0,0,1-63.7,22A104.41,104.41,0,0,1,75,278.55a145.21,145.21,0,0,0,78.62,23"
        fill="#fff" />
    </svg>
  </a>

  <a class="square-link-insta" title="Instagram" href="https://instagram.com/world_sports_betting/" target="_blank"
    rel="noopener">
    <svg _ngcontent-wwo-c16="" id="fb-logo" width="22" height="26" xmlns="http://www.w3.org/1999/xlink" data-name="Logo"
      viewBox="0 0 25 24">
      <path _ngcontent-wwo-c16=""
        d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
        fill="#fff">
      </path>
    </svg>
  </a>

  <a class="square-link-pinterest" title="Pinterest" href="https://za.pinterest.com/worldsportsbet" target="_blank"
    rel="noopener">
    <svg _ngcontent-wwo-c16="" id="fb-logo" width="20" height="20" xmlns="http://www.w3.org/1999/xlink" data-name="Logo"
      viewBox="0 0 25 24">
      <path _ngcontent-wwo-c16=""
        d="M12 0c-6.627 0-12 5.372-12 12 0 5.084 3.163 9.426 7.627 11.174-.105-.949-.2-2.405.042-3.441.218-.937 1.407-5.965 1.407-5.965s-.359-.719-.359-1.782c0-1.668.967-2.914 2.171-2.914 1.023 0 1.518.769 1.518 1.69 0 1.029-.655 2.568-.994 3.995-.283 1.194.599 2.169 1.777 2.169 2.133 0 3.772-2.249 3.772-5.495 0-2.873-2.064-4.882-5.012-4.882-3.414 0-5.418 2.561-5.418 5.207 0 1.031.397 2.138.893 2.738.098.119.112.224.083.345l-.333 1.36c-.053.22-.174.267-.402.161-1.499-.698-2.436-2.889-2.436-4.649 0-3.785 2.75-7.262 7.929-7.262 4.163 0 7.398 2.967 7.398 6.931 0 4.136-2.607 7.464-6.227 7.464-1.216 0-2.359-.631-2.75-1.378l-.748 2.853c-.271 1.043-1.002 2.35-1.492 3.146 1.124.347 2.317.535 3.554.535 6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12z"
        fill-rule="evenodd" clip-rule="evenodd" fill="#fff">
      </path>
    </svg>
  </a>

  <a class="square-link-tumblr" title="Tumblr" href="https://worldsportsbet.tumblr.com/" target="_blank" rel="noopener">
    <svg _ngcontent-wwo-c16="" id="fb-logo" width="20" height="20" xmlns="http://www.w3.org/1999/xlink" data-name="Logo"
      viewBox="0 0 25 24">
      <path _ngcontent-wwo-c16=""
        d="M19.512 17.489l-.096-.068h-3.274c-.153 0-.16-.467-.163-.622v-5.714c0-.056.045-.101.101-.101h3.82c.056 0 .101-.045.101-.101v-5.766c0-.055-.045-.1-.101-.1h-3.803c-.055 0-.1-.045-.1-.101v-4.816c0-.055-.045-.1-.101-.1h-7.15c-.489 0-1.053.362-1.135 1.034-.341 2.778-1.882 4.125-4.276 4.925l-.267.089-.068.096v4.74c0 .056.045.101.1.101h2.9v6.156c0 4.66 3.04 6.859 9.008 6.859 2.401 0 5.048-.855 5.835-1.891l.157-.208-1.488-4.412zm.339 4.258c-.75.721-2.554 1.256-4.028 1.281l-.165.001c-4.849 0-5.682-3.701-5.682-5.889v-7.039c0-.056-.045-.101-.1-.101h-2.782c-.056 0-.101-.045-.101-.101l-.024-3.06.064-.092c2.506-.976 3.905-2.595 4.273-5.593.021-.167.158-.171.159-.171h3.447c.055 0 .101.045.101.101v4.816c0 .056.045.101.1.101h3.803c.056 0 .101.045.101.1v3.801c0 .056-.045.101-.101.101h-3.819c-.056 0-.097.045-.097.101v6.705c.023 1.438.715 2.167 2.065 2.167.544 0 1.116-.126 1.685-.344.053-.021.111.007.13.061l.995 2.95-.024.104z"
        fill-rule="evenodd" clip-rule="evenodd" fill="#fff">
      </path>
    </svg>
  </a>

  <a class="square-link-youtube" title="Youtube" href="http://www.youtube.com/user/WorldSportsBetting" target="_blank"
    rel="noopener">
    <svg _ngcontent-wwo-c16="" id="fb-logo" width="22" height="26" xmlns="http://www.w3.org/1999/xlink" data-name="Logo"
      viewBox="0 0 25 24">
      <path _ngcontent-wwo-c16=""
        d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
        fill="#fff">
      </path>
    </svg>
  </a>
</div>

<!--Copyright-->
<div class="copyright p-3">
  <div class="container text-center">
      <p>
    © Copyright 2002 -
    <script type="text/javascript">document.write(new Date().getFullYear())</script>{{date| date:'yyyy'}}
    World Sports Betting. All Rights Reserved.
    </p>
  </div>
</div>

</div>
</div>