import { Component, OnInit } from '@angular/core';
import {HttpService} from '../http.service';
import {MyAccount} from '../_models/myaccount';
import { NgxSpinnerService } from "ngx-spinner";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss']
})
export class MyaccountComponent implements OnInit {

  redeemForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  success = false;
  returnUrl: string;
  error = '';

  userdata: MyAccount;
  issueData: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private _http: HttpService,
    private SpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.redeemForm = this.formBuilder.group({
      redeem: ['', Validators.required],
  });
    this.SpinnerService.show();  
    this._http.getUser().subscribe(data => {
      this.userdata = <MyAccount>data
      // console.log(this.userdata);
      this.SpinnerService.hide();  
    }
  );
  }

  get f() { return this.redeemForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.redeemForm.invalid) {
        return;
    }
    this.SpinnerService.show();
    this.loading = true;
    this._http.redeem(this.f.redeem.value).subscribe(data => {
      console.log(data);
      var returnData = <any>data;
      console.log(returnData.message);
      if (returnData.status == 0) {
        this.error = returnData.message;
      }else {
        this.success = true; //Come back!
      }
      this._http.getUser().subscribe(data => {
        this.userdata = <MyAccount>data
        // console.log(this.userdata);
        this.SpinnerService.hide();
      }
      );
      this.loading = false;
    }
  );
}

}
