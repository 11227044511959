<!-- Toolbar -->
<head>
  <!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-TFK4FF');</script>
  <!-- End Google Tag Manager -->  
</head>

<body>
  <!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TFK4FF"
  height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <!-- End Google Tag Manager (noscript) -->
  

<div class="toolbar d-flex justify-content-center" role="banner">

    <div class="logo">
      <a class="mainNavItem pr-2 text-white" routerLink="/myaccount">Account</a>
      <a class=" mainNavItem pr-2 text-white" routerLink="/about">About</a>
      <img id="logo-img" width="150" alt="Angular Logo"  routerLink="/" src="https://cdn.worldsportsbetting.co.za/images/club-prive/CP-NavBar-150x58.png" />
      <a class=" mainNavItem pr-2 text-white" routerLink="/rewards">Rewards</a>
      <a class=" mainNavItem pr-2 text-white" routerLink="/tiers">Tiers</a>
    </div>
        <div class="nav-flex">

          <a id="login-btn" *ngIf="!(loggedIn | async)" class="pr-2 text-white" routerLink="/login" (click)="onLoginHandler()">Login</a>
          <a id="logout-btn" *ngIf="(loggedIn | async)" class="pr-2 text-white" (click)="onLogoutHandler()">Logout</a>
    </div>

    <div class="menuToggle">
      <div class="hamburgerContainer">
        <span id="bar1" [class.changeBar1]="open == false" (click)="hamburgerToggleHandler()"></span>
        <span id="bar2" [class.changeBar2]="open == false" (click)="hamburgerToggleHandler()"></span>
        <span id="bar3" [class.changeBar3]="open == false" (click)="hamburgerToggleHandler()"></span>
      </div>

    </div>

    <div class="mobile-Menu" [class.mobile-Menu-open]="open == false" (click)="hamburgerToggleHandler()">
      <ul id="mobile-Menu__list">
        <li class="mobile-Menu__list__item"><a class="pr-2 text-white" routerLink="/about">About</a></li>
        <li class="mobile-Menu__list__item"><a class="pr-2 text-white" routerLink="/rewards">Rewards</a></li>
        <li class="mobile-Menu__list__item"><a class="pr-2 text-white" routerLink="/tiers">Tiers</a></li>
        <li class="mobile-Menu__list__item"><a class="pr-2 text-white" routerLink="/myaccount">Account</a></li>
        <li class="mobile-Menu__list__item"><a id="login-btn-mobile" *ngIf="!(loggedIn | async)" class="pr-2 text-white" routerLink="/login" (click)="onLoginHandler()">Login</a>
            <a id="logout-btn" *ngIf="(loggedIn | async)" class="pr-2 text-white" (click)="onLogoutHandler()">Logout</a></li>
      </ul>
    </div>

  </div>
  
</body>