import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }


  getUser() {
    var auth_token = JSON.parse(localStorage.getItem('currentUser'));
    const headers = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token.token}`
    })
    return this.http.get('https://www.worldsportsbetting.co.za/clubprive/api/user',{ headers: headers })
  }

  redeem(amount) {
    var auth_token = JSON.parse(localStorage.getItem('currentUser'));
    const headers = new HttpHeaders ({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token.token}`
    })
    return this.http.post('https://www.worldsportsbetting.co.za/clubprive/api/issue',{amount: amount},{ headers: headers })
  }
}
