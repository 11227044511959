<!-- About Body -->

<body>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TFK4FF"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->

    <div class="content w-100" role="main">

        <!-- Welcome Message -->
        <div class="row main-top-row" style="margin-top: -20px;">
            <div class="container about-header">
                <img class="about-header-left" src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-about-coin-icon-left.png" style="margin-right: -2rem; height: auto; width: 20%; transform: rotate(40deg);">
                <h1 class="p-3 h-title">About</h1>
                <img class="about-header-right" src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-about-roulette-icon.png" style="height: auto; width: 30%;">
            </div>

            <div class="container">
                <!-- <div class="container">
                    <img class="about-img" src="https://cdn.worldsportsbetting.co.za/images/club-prive/Casino-Casino-Gold-prive.png">
                </div> -->
                <p class="text-gold">
                    World Sports Betting’s Club Prive is all about you!
                    <br> A first of its kind live games program rewards you in more ways than one when you play any of our live games online.
                </p>
            </div>
            <div class="container">
                <p class="text-gold">
                    Earn FreePlay and enjoy special promotions, discounts and lifestyle rewards when you join SA's premier live games club.
                    <br> Open an account today or log into your WSB account and play any of our live games to join the club automatically! For more info visit <a href="https://clubprive.co.za/?_ga=2.105023680.296365312.1614338130-1270519537.1576256998"
                        target="_new">CLUB PRIVE</a> or email <a href="mailto:rewards@wsb.co.za">rewards&#64;wsb.co.za</a>.
                </p>
            </div>
            <!-- <div class="container">
            <div class="d-flex justify-content-center">
                <h3>
                    <span class="header-subtext p-3 text-gold" style="text-transform: uppercase; font-size: 24px;">Welcome To</span>
                </h3>
            </div>
            <div class="row h-title-holder" style="float: right;">
                <h1>
                    <span class="header-subtext p-3 h-title" style="font-size: 94px;">Club</span>
                    <span class="h-title" style="font-family: Lato; font-weight: normal; font-size: 80px;">PRIVE</span>
                </h1>
                </div>
        </div> -->
        </div>

        <div class="container">

        </div>


        <!-- <div class="col-sm-12 row d-flex justify-content-center">
        <a type="button" class="btn btn-gold-sm btn-sm mt-4" href="https://wc.worldsportsbetting.co.za/"></a>

    </div> -->

    </div>
</body>