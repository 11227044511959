import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { User } from './_models/user';

@Component({
  selector: 'app-root',
  // templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  template: ' <app-header></app-header><router-outlet></router-outlet><app-footer></app-footer> '
})
export class AppComponent {
  currentUser: User;
  title = 'ClubPrive';

  @Input() logOut: boolean;


  constructor(
    private router: Router,
    private authenticationService: AuthService
) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
}


logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
}
}
