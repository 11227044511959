import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ts-cs',
  templateUrl: './ts-cs.component.html',
  styleUrls: ['./ts-cs.component.css']
})
export class TsCsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
