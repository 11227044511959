<p-accordion>
    <p-accordionTab header="Orange Tier">
        <p>- Earn monthly free play points.</p>
        <p>- Inclusion in exclusive promotions.</p>
    </p-accordionTab>
    <p-accordionTab header="Bronze Tier">
        <p>- Earn monthly free play points.</p>
        <p>- Inclusion in exclusive promotions.</p>
        <p>- R50 annual birthday voucher.</p>
        <p>- Free entry into monthly R500 cash draw.</p>
    </p-accordionTab>
    <p-accordionTab header="Silver Tier">
        <p>- Earn monthly free play points.</p>
        <p>- Inclusion in exclusive promotions.</p>
        <p>- R100 annual birthday voucher.</p>
        <p>- Free entry into monthly R1000 cash draw.</p>
        <p>- 10% discount at all Shuffle Up poker events. Limited to 1 event. Maximum match: R2 000.</p>    
    </p-accordionTab>
    <p-accordionTab header="Gold Tier">
        <p>- Earn monthly free play points.</p>
        <p>- Inclusion in exclusive promotions.</p>
        <p>- R300 annual birthday voucher.</p>
        <p>- Free entry into monthly R1500 cash draw.</p>
        <p>- 20% discount at all Shuffle Up poker events. Limited to 1 event. Maximum match: R4 000.</p> 
        <p>- 5% discount on all purchases with Direct Deals.</p>    
    </p-accordionTab>
    <p-accordionTab header="Platinum Tier">
        <p>- Earn monthly free play points.</p>
        <p>- Inclusion in exclusive promotions.</p>
        <p>- R500 annual birthday voucher.</p>
        <p>- Free entry into monthly R2500 cash draw.</p>
        <p>- 30% discount at all Shuffle Up poker events.Limited to 1 event. Maximum match: R7 000.</p> 
        <p>- 5% discount on all purchases with Direct Deals.</p>    
        <p>- 2 free general tickets to the Durban july or Met every year.</p>
        <p>- Qualify for occasional event invites based on play.</p>
    </p-accordionTab>
    <p-accordionTab header="Diamond Tier">
        <p>- Earn monthly free play points.</p>
        <p>- Inclusion in exclusive promotions.</p>
        <p>- R1000 annual birthday voucher.</p>
        <p>- Free entry into monthly R5000 cash draw.</p>
        <p>- 40% discount at all Shuffle Up poker events. Limited to 1 event. Maximum match: R10 000.</p> 
        <p>- 5% discount on all purchases with Direct Deals.</p>    
        <p>- 2 free general tickets to the Durban july or Met every year.</p>
        <p>- Qualify for occasional event invites based on play.</p>
        <p>- Qualify for occasional food and beverage offers based on play.</p>    
    </p-accordionTab>
    <p-accordionTab header="Black Tier">
        <p>- Earn monthly free play points.</p>
        <p>- Inclusion in exclusive promotions.</p>
        <p>- R5000 annual birthday voucher.</p>
        <p>- Free entry into monthly R10 000 cash draw.</p>
        <p>- 50% discount at all Shuffle Up poker events. Limited to 1 event. Maximum match: R20 000.</p> 
        <p>- 5% discount on all purchases with Direct Deals.</p>
        <p>- Exclusive access to concierge desk.</p>
        <p>- Qualify for exclusive deals from Safari.com.</p>  
        <p>- 2 free general tickets to the Durban july or Met every year.</p>
        <p>- Qualify for occasional event invites based on play.</p>
        <p>- Qualify for occasional food and beverage offers based on play.</p> 
        <p>- Qualify for exclusive Uber Eats offers every month.</p>
        <p>- Qualify for exclusive spa offerings based on play.</p>
        <p>- Qualify for exclusive golf offerings based on play.</p>
        <p>- 15% discount on all bookings through Mars Travel.</p>
    </p-accordionTab>
    <p-accordionTab header="Super Black Tier">
        <p>- Earn monthly free play points.</p>
        <p>- Inclusion in exclusive promotions.</p>
        <p>- R10 000 annual birthday voucher.</p>
        <p>- Free entry into monthly R20 000 cash draw.</p>
        <p>- 60% discount at all Shuffle Up poker events.  Limited to 1 event. Maximum match: R40 000.</p> 
        <p>- 5% discount on all purchases with Direct Deals.</p>
        <p>- Exclusive access to concierge desk.</p>
        <p>- Exclusive access to personal host.</p>
        <p>- Qualify for exclusive deals from Safari.com.</p>  
        <p>- 2 free general tickets to the Durban july or Met every year.</p>
        <p>- Qualify for VIP invites to events.</p>
        <p>- Qualify for 2 free nights at any 4-star hotel in South Africa once a year.</p>
        <p>- Qualify for occasional food and beverage offers based on play.</p> 
        <p>- Qualify for exclusive Uber Eats offers every month.</p>
        <p>- Qualify for exclusive spa offerings based on play.</p>
        <p>- Qualify for exclusive golf offerings based on play.</p>
        <p>- 20% discount on all bookings through Mars Travel.</p>   
    </p-accordionTab>
</p-accordion>