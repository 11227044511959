<!-- About Body -->
<body>
<div class="content" role="main">

    <!-- Welcome Message -->
    <div class="row main-top-row" style="margin-top: -50px;">
        <h1 class="h-title pb-3 pl-3" >Ts and Cs</h1>
        <div class="container">
            <p class="text-gold">• All Freeplay is earned based on official live games turnover.</p>
            <p class="text-gold">• Sports & racing play will be treated as separate.</p>
            <p class="text-gold">• Free Play will be issued into your live games wallet and can not be converted to cash or sport free play.</p> 
            <p class="text-gold">• World Sports Betting has the right to amend, improve or cancel any part of the rewards program at any time as it deems necessary</p>
            <p class="text-gold">• World Sports Betting has the right to exclude any player from the Club Prive promotions and associated benefits.
            <p class="text-gold">• Only 1 account is allowed per person. Any abuse of rewards will result in all associated rewards being cancelled.</p>
            <p class="text-gold">• Only World Sports Betting account holders can take part in the World Sports Betting Club Prive program.</p>
            <p class="text-gold">• Winners of monthly draws will be selected at random and winners announced via social media. World Sports Betting will NOT use any players full names for prize announcements of any promotion.</p>
            <p class="text-gold">• Any player who self excludes from the live games section, is registered on the exclusion list with the national board or has previously requested to be excluded from rewards will NOT be eligible for any rewards associated to the program.</p>
            <p class="text-gold">• World Sports Betting, its partners and directors take no responsibility for any loss/injury that may occur on excursions associated to the program.</p>
            <p class="text-gold">• World Sports Betting takes no responsibility for any sport or event cancelled.</p>
            <p class="text-gold">• VIP event invites are strictly done by personal invite only at the discretion of the World Sports Betting management team and all event invites will be on a first come first served basis.</p>
            <p class="text-gold">• Travel reward discounts are limited to R10 000 per year for Black and R25 000 per year for Super Black card holders.</p>
            <p class="text-gold">• Direct Deals discount valid on purchases of R2500 or more.</p>
            <p class="text-gold">• Cannot be used in conjunction with any other special offer. T&Cs apply.</p>
            <p class="text-gold">• Safari.com deals will be shared when available with all qualifying members.</p>
            <p class="text-gold">• All bookings to take place directly through safari.com</p>
            <p class="text-gold">• World Sports Betting general terms & conditions apply and supersede all rewards program terms.</p>
            <p class="text-gold">• Promotional game winnings limited to 75x Free Play value.</p>
            <p class="text-gold">• Points earned as 0.1% of turnover.</p>
            <p class="text-gold">• For help please email <a href="mailto:rewards@wsb.co.za">rewards&#64;wsb.co.za</a></p> 
        </div>
    </div>

</div>
</body>