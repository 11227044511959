    <!-- About Body -->

<body>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TFK4FF"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->

    <div class="content" role="main">
        <!-- Welcome Message -->
        <div class="container rewards-header">
            <img class="rewards-header-left" src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-rewards-balloons-left.png">
            <h1 class="p-3 h-title">Rewards</h1>
            <img class="rewards-header-right" src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-rewards-present.png">
        </div>
        <div class="row main-top-row" style="margin-top: -20px;">
            <div style="overflow-x: hidden; position: relative;">


                <!-- <img class="rewards-img pb-4" src="https://cdn.worldsportsbetting.co.za/images/club-prive/rewards.png" alt="" title="rewards"> -->
            </div>

            <div style="width: 100%;">
                <h1>
                    <p class="text-gold pl-3">Club Prive Member Benefits</p>
                </h1>
    
                <accordion-component></accordion-component>
            </div>


            <!-- <div class="flex-container tier-container p-1 desktop-table">


                <table class="table tiers-table prive-color ">
                    <thead>
                        <tr>
                            <th class="prive-color" scope="col" colspan="2">Tier
                            </th>
                            <th class="prive-color" scope="col">Orange</th>
                            <th class="prive-color" scope="col">Bronze</th>
                            <th class="prive-color" scope="col">Silver</th>
                            <th class="prive-color" scope="col">Gold</th>
                            <th class="prive-color" scope="col">Platinum</th>
                            <th class="prive-color" scope="col">Diamond</th>
                            <th class="prive-color" scope="col">Black</th>
                            <th class="prive-color" scope="col">Super Black</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="2">Earn Freeplay monthly </td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td colspan="2">Inclusion in exclusive live games promotions</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td colspan="2">Annual Live Games Birthday Voucher</td>
                            <td>-</td>
                            <td>R50</td>
                            <td>R100</td>
                            <td>R300</td>
                            <td>R500</td>
                            <td>R1,000</td>
                            <td>R5,000</td>
                            <td>R10,000</td>
                        </tr>
                        <tr>
                            <td colspan="2">Entry into Monthly Tiered draws for CASH prize</td>
                            <td>-</td>
                            <td>R500</td>
                            <td>R1,000</td>
                            <td>R1,500</td>
                            <td>R2,500</td>
                            <td>R5,000</td>
                            <td>R10,000</td>
                            <td>R20,000</td>
                        </tr>
                        <tr>
                            <td colspan="2">Exclusive discounts from safari.com</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td colspan="2">Discount on all travel bookings through Mars Travel *</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>15%</td>
                            <td>20%</td>
                        </tr>
                        <tr>
                            <td colspan="2">Free general entry tickets to the Durban July or MET per year*</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>2</td>
                            <td>2</td>
                            <td>2</td>
                            <td>2</td>
                        </tr>
                        <tr>
                            <td colspan="2">1 Free round of Golf for you and 3 friends per Quarter</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td colspan="2">VIP event invites*</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td colspan="2">Discount on All Shuffle Up Poker poker tournaments</td>
                            <td>-</td>
                            <td>-</td>
                            <td>10%</td>
                            <td>20%</td>
                            <td>30%</td>
                            <td>40%</td>
                            <td>50%</td>
                            <td>60%</td>
                        </tr>
                        <tr>
                            <td colspan="2">5% Discount on all purchases with Direct Deals</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td colspan="2">Exclusive monthly Uber/Uber eats vouchers</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td colspan="2">Access to exclusive concierge services</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="mobile-only-table">
                <h1>
                    <p class="text-gold pl-3" style="font-size: 6.5vw; margin-bottom: -17px;">Club Prive Member Benefits
                    </p>
                </h1>
                <div class="statement-box-cont mb-4">
                    <table width="100%" cellpadding="5" cellspacing="0" border="0" class="statment-font-size">
                        <tbody>
                            <tr class="">
                                <td width="73%" class="text-right">Tier</td>
                                <td class="text-bold-gold">Orange</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right">Earn Freeplay monthly</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right">Inclusion in exclusive live games promotions</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="statement-box-cont mb-4">
                    <table width="100%" cellpadding="5" cellspacing="0" border="0" class="statment-font-size">
                        <tbody>
                            <tr class="">
                                <td width="73%" class="text-right ">Tier</td>
                                <td class="text-bold-gold">Bronze</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Earn Freeplay monthly</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Inclusion in exclusive live games promotions</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Annual Live Games Birthday Voucher</td>
                                <td class="text-bold-gold">R50</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Entry into Monthly Tiered draws for CASH prize</td>
                                <td class="text-bold-gold">R500</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="statement-box-cont mb-4">
                    <table width="100%" cellpadding="5" cellspacing="0" border="0" class="statment-font-size">
                        <tbody>
                            <tr class="">
                                <td width="73%" class="text-right ">Tier</td>
                                <td class="text-bold-gold">Silver</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Earn Freeplay monthly</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Inclusion in exclusive live games promotions</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Annual Live Games Birthday Voucher</td>
                                <td class="text-bold-gold">R100</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Discount on All Shuffle Up Poker poker tournaments
                                </td>
                                <td class="text-bold-gold">10%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="statement-box-cont mb-4">
                    <table width="100%" cellpadding="5" cellspacing="0" border="0" class="statment-font-size">
                        <tbody>
                            <tr class="">
                                <td width="73%" class="text-right ">Tier</td>
                                <td class="text-bold-gold">Gold</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Earn Freeplay monthly</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Inclusion in exclusive live games promotions</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Annual Live Games Birthday Voucher</td>
                                <td class="text-bold-gold">R300</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Entry into Monthly Tiered draws for CASH prize</td>
                                <td class="text-bold-gold">R1,500</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Discount on All Shuffle Up Poker poker tournaments
                                </td>
                                <td class="text-bold-gold">20%</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">5% Discount on all purchases with Direct Deals</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="statement-box-cont mb-4">
                    <table width="100%" cellpadding="5" cellspacing="0" border="0" class="statment-font-size">
                        <tbody>
                            <tr class="">
                                <td width="73%" class="text-right ">Tier</td>
                                <td class="text-bold-gold">Platinum</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Earn Freeplay monthly</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Inclusion in exclusive live games promotions</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Annual Live Games Birthday Voucher</td>
                                <td class="text-bold-gold">R500</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Entry into Monthly Tiered draws for CASH prize</td>
                                <td class="text-bold-gold">R2,500</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Exclusive discounts from safari.com</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Free general entry tickets to the Durban July or MET per year*</td>
                                <td class="text-bold-gold">2</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Discount on All Shuffle Up Poker poker tournaments
                                </td>
                                <td class="text-bold-gold">30%</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">5% Discount on all purchases with Direct Deals</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="statement-box-cont mb-4">
                    <table width="100%" cellpadding="5" cellspacing="0" border="0" class="statment-font-size">
                        <tbody>
                            <tr class="">
                                <td width="73%" class="text-right ">Tier</td>
                                <td class="text-bold-gold">Diamond</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Earn Freeplay monthly</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Inclusion in exclusive live games promotions</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Annual Live Games Birthday Voucher</td>
                                <td class="text-bold-gold">R1,000</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Entry into Monthly Tiered draws for CASH prize</td>
                                <td class="text-bold-gold">R5,000</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Exclusive discounts from safari.com</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Free general entry tickets to the Durban July or MET per year*</td>
                                <td class="text-bold-gold">2</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Discount on All Shuffle Up Poker poker tournaments
                                </td>
                                <td class="text-bold-gold">40%</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">5% Discount on all purchases with Direct Deals</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Exclusive monthly Uber/Uber eats vouchers</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="statement-box-cont mb-4">
                    <table width="100%" cellpadding="5" cellspacing="0" border="0" class="statment-font-size">
                        <tbody>
                            <tr class="">
                                <td width="73%" class="text-right ">Tier</td>
                                <td class="text-bold-gold">Black</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Earn Freeplay monthly</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Inclusion in exclusive live games promotions</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Annual Live Games Birthday Voucher</td>
                                <td class="text-bold-gold">R5,000</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Entry into Monthly Tiered draws for CASH prize</td>
                                <td class="text-bold-gold">R10,000</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Exclusive discounts from safari.com</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Discount on all travel bookings through Mars Travel *
                                </td>
                                <td class="text-bold-gold">15%</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Free general entry tickets to the Durban July or MET per year*</td>
                                <td class="text-bold-gold">2</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Discount on All Shuffle Up Poker poker tournaments
                                </td>
                                <td class="text-bold-gold">50%</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">5% Discount on all purchases with Direct Deals</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Exclusive monthly Uber/Uber eats vouchers</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="statement-box-cont mb-4">
                    <table width="100%" cellpadding="5" cellspacing="0" border="0" class="statment-font-size">
                        <tbody>
                            <tr class="">
                                <td width="73%" class="text-right ">Tier</td>
                                <td class="text-bold-gold">Super Black</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Earn Freeplay monthly</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Inclusion in exclusive live games promotions</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Annual Live Games Birthday Voucher</td>
                                <td class="text-bold-gold">R10,000</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Entry into Monthly Tiered draws for CASH prize</td>
                                <td class="text-bold-gold">R20,000</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Exclusive discounts from safari.com</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Discount on all travel bookings through Mars Travel *
                                </td>
                                <td class="text-bold-gold">20%</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Free general entry tickets to the Durban July or MET per year*</td>
                                <td class="text-bold-gold">2</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">1 Free round of Golf for you and 3 friends per Quarter
                                </td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">VIP event invites*</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Discount on All Shuffle Up Poker poker tournaments
                                </td>
                                <td class="text-bold-gold">60%</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">5% Discount on all purchases with Direct Deals</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Exclusive monthly Uber/Uber eats vouchers</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                            <tr>
                                <td width="73%" class="text-right ">Access to exclusive concierge services</td>
                                <td class="text-bold-gold">Yes</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> -->

            <div class="container how-to">
                <p class="text-gold pl-3 pt-3 claim" style="font-size: 1.2vw;">To claim your rewards:</p>
                <p class="text-gold pl-3">• Freeplay is earned and issued monthly.</p>
                <p class="text-gold pl-3">• All live game players will be automatically entered into their respective monthly tier bonus draws and 1 winner will be selected at random per tier per month.</p>
                <p class="text-gold pl-3">• Your live games Birthday voucher will be automatically issued on your Birthday every year.</p>
                <p class="text-gold pl-3">• To claim your free entry tickets to events, please email <a href="mailto:rewards@wsb.co.za">rewards&#64;wsb.co.za</a> a minimum of 14 days before the event.</p>
                <p class="text-gold pl-3">• Free Tickets are issued based on availability on a first come first served basis.
                </p>
                <p class="text-gold pl-3">• VIP event invites will be sent to qualifying accounts throughout the year subject to event calendars.</p>
                <p class="text-gold pl-3">• Golf bookings are subject to availability. All requests must be made 14 days in advance. Carts for own account.</p>
                <p class="text-gold pl-3">• To claim your travel discounts, email your WSB account number and a copy of your ID to the Mars travel team when you request your booking.</p>
                <p class="text-gold pl-3">• For any additional rewards queries email <a href="mailto:rewards@wsb.co.za">rewards&#64;wsb.co.za</a> or contact our 24/7 support line on <a href="tel:+27860103393">0860 10 33 93</a>.</p>
            </div>

            <div class="container how-to">
                <p class="text-gold pl-3 pt-3 claim" style="font-size: 1.2vw;">Our Partners:</p>

                <div class="sponsors-container py-3">
                    <div class="container text-left partners-logos-container">
                        <img class="p-2 mt-2 Shuffleuppoker" z src="https://cdn.worldsportsbetting.co.za/images/club-prive/wsb-club-prive-sup-shuffleuppoker-logo-rewards.svg" alt="Shuffle Up Poker">
                        <img class="p-2 Mars-travel" z src="https://cdn.worldsportsbetting.co.za/images/club-prive/wsb-club-prive-mars-travel-logo-rewards.svg" alt="Mars Travel">
                        <img class="p-2 Direct-deals" src="https://cdn.worldsportsbetting.co.za/images/club-prive/wsb-club-prive-direct-deals-logo-rewards.svg" alt="Direct Deals">
                        <img class="p-2 Safari" src="https://cdn.worldsportsbetting.co.za/images/club-prive/wsb-club-prive-safari-logo-rewards.svg" alt="Safari.com">
                        <img class="p-2 Uber-logo" src="https://cdn.worldsportsbetting.co.za/images/club-prive/wsb-club-prive-uber-logo-rewards.svg" alt="Teaming up with Uber">
                        <img class="p-2 Uber-eats" src="https://cdn.worldsportsbetting.co.za/images/club-prive/wsb-club-prive-uber-eats-logo-rewards.svg" alt="Teaming up with Uber Eats">
                    </div>
                </div>
            </div>

            <!-- <div class="col-sm-12 row">
                <a type="button" class="btn btn-gold-sm btn-sm mt-4" style="margin-left: 3vw;"
                    href="https://wc.worldsportsbetting.co.za/"></a>

            </div> -->

        </div>
    </div>
</body>