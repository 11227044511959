import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent} from './home/home.component';
import { AboutComponent} from './about/about.component';
import { RewardsComponent} from './rewards/rewards.component';
import { TiersComponent} from './tiers/tiers.component';
import { TsCsComponent } from './ts-cs/ts-cs.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { ComingComponent } from './coming/coming.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'coming',
    component: ComingComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'rewards',
    component: RewardsComponent
  },
  {
    path: 'tiers',
    component: TiersComponent
  },
  {
    path: 'tscs',
    component: TsCsComponent
  },
  {path: 'myaccount',  component: MyaccountComponent, canActivate: [AuthGuard] },
  {path: 'login',  component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
