<body>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TFK4FF"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->

    <div class="col-md-6 offset-md-3 login-container">
        <div class="card dark-bg">
            <div class="card-body">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="username" class="text-white">Username</label>
                        <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div>Username is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="password" class="text-white">Password</label>
                        <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                        <div class="invalid-feedback">
                            <div>Password is required</div>
                        </div>
                    </div>
                    <button [disabled]="loading" class="btn btn-gold">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>
                    <div *ngIf="errorMsg" class="alert alert-danger mt-3 mb-0">{{errorMsg}}</div>
                </form>
            </div>
        </div>
    </div>
</body>