<!-- About Body -->

<body>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TFK4FF"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->

    <div class="content" role="main">

        <!-- Welcome Message -->
        <div class="row main-top-row" style="margin-top: -20px;">
            <div class="container tiers-header">
                <img class="tiers-header-left" src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-tiers-cards-left.png" style="height: auto; width: 30%;">
                <h1 class="p-3 h-title">Tiers</h1>
                <img class="tiers-header-right" src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-tiers-cards-right.png" style="height: auto; width: 30%;">
            </div>

            <div class="container">
                <p class="text-gold">
                    World Sports Betting’s Club Prive consists of 8 tiers all with their own rewards for you to enjoy!
                    <br> Play and earn Freeplay plus earn your tier status at the same time.
                    <br> Your tier is based on your Freeplay earned over a rolling 12 months. Tier upgrades take place monthly and tier migration is reviewed anually.
                    <br>
                    <br> Tiers are based on Freeplay earned.
                </p>
            </div>
            <div class="container">
                <!-- <div class="col-sm-12 row">
                <a type="button" class="btn btn-gold-sm btn-sm mt-4 mb-5" href="https://wc.worldsportsbetting.co.za/"></a>
        
            </div> -->
                <table class="table tiers-table prive-color">
                    <thead>
                        <tr>
                            <th class="prive-color" scope="col">Free Play Earned
                            </th>
                            <th class="prive-color" scope="col">Tier</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0</td>
                            <td>Orange</td>
                        </tr>
                        <tr>
                            <td>150</td>
                            <td>Bronze</td>
                        </tr>
                        <tr>
                            <td>300</td>
                            <td>Silver</td>
                        </tr>
                        <tr>
                            <td>1000</td>
                            <td>Gold</td>
                        </tr>
                        <tr>
                            <td>2000</td>
                            <td>Platinum</td>
                        </tr>
                        <tr>
                            <td>5000</td>
                            <td>Diamond</td>
                        </tr>
                        <tr>
                            <td>15000</td>
                            <td>Black</td>
                        </tr>
                        <tr>
                            <td>30000</td>
                            <td>Super Black</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="container">

        </div>
    </div>
</body>