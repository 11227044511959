<body>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TFK4FF"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->

    <div class="content container p-5" role="main">
        <ng-container *ngIf="userdata">
            <div>
                <h3><span class="header-subtext text-gold" style="text-transform: uppercase; font-size: 34px;">Welcome</span></h3>
                <ng-template *ngIf="userdata.Punter.firstName">{{userdata.Punter.firstName }}</ng-template>
                <div class="text-border">
                    <p class="text-gold pb-2">Your Current Point balance is: {{ userdata.tier.points }}</p>
                    <p class="text-gold">Your Current Tier: {{ userdata.tier.tierName }}</p>
                </div>
                <div *ngIf="userdata.tier.tierName === 'Silver'">
                    <img src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-silver-my-account.png" class="p-3 club-prive-cards">
                </div>
                <div *ngIf="userdata.tier.tierName === 'Orange'">
                    <img src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-orange-my-account.png" class="p-3 club-prive-cards">
                </div>
                <div *ngIf="userdata.tier.tierName === 'Bronze'">
                    <img src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-bronze-my-account.png" class="p-3 club-prive-cards">
                </div>
                <div *ngIf="userdata.tier.tierName === 'Gold'">
                    <img src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-gold-my-account.png" class="p-3 club-prive-cards">
                </div>
                <div *ngIf="userdata.tier.tierName === 'Platinum'">
                    <img src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-platinum-my-account.png" class="p-3 club-prive-cards">
                </div>
                <div *ngIf="userdata.tier.tierName === 'Diamond'">
                    <img src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-diamond-my-account.png" class="p-3 club-prive-cards">
                </div>
                <div *ngIf="userdata.tier.tierName === 'Black'">
                    <img src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-black-my-account.png" class="p-3 club-prive-cards">
                </div>
                <div *ngIf="userdata.tier.tierName === 'Super Black'">
                    <img src="https://cdn.worldsportsbetting.co.za/wc/wsb-club-prive-super-black-my-account.png" class="p-3 club-prive-cards">
                </div>
                <!-- 
<div *ngFor="let tierName of userdata">

</div> -->
            </div>
            <div class="card-body">
                <form [formGroup]="redeemForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="redeem" class="text-gold">Redeem</label>
                        <input type="number" formControlName="redeem" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.redeem.errors }" />
                        <div *ngIf="submitted && f.redeem.errors" class="invalid-feedback">
                            <div *ngIf="f.redeem.errors.required">Redemption is required</div>
                        </div>
                    </div>
                    <button [disabled]="loading" class="btn btn-primary btn-gold">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Redeem
        </button>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                <!--    <div class=" alert alert-success mt-5 mb-0">
                        <p>Your points have been redeemed!</p>
                        <p>To use your points, please click <a  id="linkSuccess" href="https://wc.worldsportsbetting.co.za/evo.php?table_id=game_shows">here</a> to enter the Evolution Live Games Lobby and click on the <img src="data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 66 72' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cfilter x='-24%25' y='-21.5%25' width='148%25' height='142.9%25' filterUnits='objectBoundingBox' id='a'%3E%3CfeOffset in='SourceAlpha' result='shadowOffsetOuter1'/%3E%3CfeGaussianBlur stdDeviation='4' in='shadowOffsetOuter1' result='shadowBlurOuter1'/%3E%3CfeColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0' in='shadowBlurOuter1'/%3E%3C/filter%3E%3Cpath d='M230.61 46.356v24.576h-20.339V46.356h20.34zm-22.881 0v24.576h-20.34V46.356h20.34zM218.424 15c3.834 0 6.953 3.148 6.953 7.018a7.027 7.027 0 0 1-.846 3.352H234v16.749h-23.184v-16.75h-3.632v16.75H184v-16.75h9.469a7.026 7.026 0 0 1-.846-3.35c0-3.87 3.12-7.019 6.953-7.019 2.836 0 5.737 1.479 7.96 4.058A14.3 14.3 0 0 1 209 21.096a14.3 14.3 0 0 1 1.464-2.038c2.223-2.579 5.124-4.058 7.96-4.058zm-18.848 3.667c-1.83 0-3.32 1.503-3.32 3.351s1.49 3.352 3.32 3.352h7.342c-.912-3.3-4.046-6.703-7.342-6.703zm18.848 0c-3.296 0-6.43 3.403-7.342 6.703h7.342c1.83 0 3.32-1.504 3.32-3.352 0-1.848-1.49-3.351-3.32-3.351z' id='b'/%3E%3C/defs%3E%3Cg transform='translate(-176 -7)' fill='none' fill-rule='evenodd'%3E%3Cuse fill='%23000' filter='url(%23a)' xlink:href='%23b'/%3E%3Cuse fill='%23FFF' xlink:href='%23b'/%3E%3C/g%3E%3C/svg%3E"> in the top right hand corner of the page.</p>
                        <p>Remember all winnings earned from Club Prive points are paid into your cash wallet once you have completed the game. Good Luck!</p>

                    </div> -->
                </form>
            </div>
            <div class="table-responsive">
                <table class="table tiers-table prive-color">
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Points</th>
                            <th>Used</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of userdata.statement">
                            <td>{{ item.Month }}</td>
                            <td>{{ item.Points }}</td>
                            <td>{{ item.USed }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</body>